import React from 'react'
import Layout from '../components/layouts/Layout'
import { InfoPagesWrapper } from '../components/layouts'
import { Button, ExtLinks } from "../components/elements"
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../components/seo"

const Musician = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { regex: "/tom-caraher/" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Example 3, Musician" />

      <InfoPagesWrapper>
        <h1>Tom Caraher, Saxophonist</h1>

        <p>
          {`Bold, striking, sophisticated. These were the main intentions that come to mind for this brochure site for Saxophonist Tom Caraher (hint - that's me!).`}
        </p>
        <ExtLinks href="https://tomcaraher.com/" target="_blank" rel="noreferrer">
          Take Me There!
        </ExtLinks>
      </InfoPagesWrapper>
      <div
        css={`
          width: 80%;
          margin: 2rem auto;
          img {
            object-fit: fill !important;
          }
        `}
      >
        <Img fluid={data.file.childImageSharp.fluid} />
      </div>
    </Layout>
  )
}

export default Musician
